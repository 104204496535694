<template>
  <div class="main">
    <!-- <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>个人信息</el-breadcrumb-item>
    </el-breadcrumb> -->
    <el-card
      shadow="hover"
      class="card-sty"
      body-style="height:100%;display: flex;flex-direction: column;justify-content: space-between;"
    >
      <div style="margin-top: 1rem" justify>
        <a-form-model
          :model="userMsgValue"
          ref="roleRefForm"
          :rules="userMsgRules"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 14 }"
        >
          <el-row :gutter="20" type="flex" justify="center">
            <el-col :span="13">
              <a-form-model-item label="用户名" prop="username">
                <a-input
                  placeholder="请输入用户名"
                  v-model="userMsgValue.username"
                />
              </a-form-model-item>
              <a-form-model-item label="性别" prop="sex">
                <a-select v-model="userMsgValue.sex" placeholder="请填选择性别">
                  <a-select-option key="男"> 男 </a-select-option>
                  <a-select-option key="女"> 女 </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="联系方式" prop="phone">
                <a-input
                  placeholder="请填写联系方式"
                  v-model="userMsgValue.phone"
                />
              </a-form-model-item>
              <a-form-model-item label="旧密码" prop="oldPassword">
                <a-input-password
                  placeholder="如果修改密码 请填写"
                  autoComplete="old-password"
                  v-model="userMsgValue.oldPassword"
                />
              </a-form-model-item>
              <a-form-model-item label="新密码" prop="newPassword" :style="{'marginBottom': (userMsgValue.newPassword ? '0' : '') }">
                <a-input-password
                  placeholder="如果修改密码 请填写"
                  autoComplete="new-password"
                  v-model="userMsgValue.newPassword"
                  @change="handlePassChange"
                />
              </a-form-model-item>
              <div class="password-tips" v-if="userMsgValue.newPassword">
                <div class="degreeBox" v-if="passLength != null && passLength">
                  <div class="d-item" :style="{background: passDegree > 0 ? degreeColor : ''}"></div>
                  <div class="d-item" :style="{background: passDegree > 1 ? degreeColor : ''}"></div>
                  <div class="d-item" :style="{background: passDegree > 2 ? degreeColor : ''}"></div>
                  <div class="d-item-text">{{passDegree == 3 ? '强' : (passDegree == 2 ? '中' : '弱')}}</div>
                </div>
              </div>
              <a-form-model-item label="确认新密码" prop="confirmNewPassword" has-feedback>
                <a-input-password
                  placeholder="如果修改密码 请填写"
                  autoComplete="confirm-new-password"
                  v-model="userMsgValue.confirmNewPassword"
                />
              </a-form-model-item>
              <a-form-model-item label="操作">
                <a-button type="primary" @click="updateUserMsg">修改</a-button>
              </a-form-model-item>
            </el-col>
          </el-row>
        </a-form-model>
      </div>
    </el-card>
  </div>
</template>

<script>

export default {
  name: "UserMsg",
  data() {
    let lengthPass = (rule, value, callback) => {
      if (value.length > 0 && value.length < 6) {
        callback(new Error('请输入6位数密码'));
      }else {
        callback();
      }
    };
    let confirmPass = (rule, value, callback) => {
      if (this.userMsgValue.newPassword && value === '') {
        callback(new Error('请确认新密码'));
      } else if (value !== this.userMsgValue.newPassword) {
        callback(new Error("两次密码输入不一致"));
      } else {
        callback();
      }
    };
    return {
      userMsgValue: {
        id: "",
        username: "",
        phone: "",
        sex: undefined,
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: ""
      },
      userMsgRules: {
        username: [
          { required: true, message: "请填写用户名", trigger: "blur" },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
        phone: [{ required: true, message: "请填写联系方式", trigger: "blur" }],
        newPassword: [{ validator: lengthPass, trigger: 'change' }],
        confirmNewPassword: [{ validator: confirmPass, trigger: 'change' }]
      },
      showTipsFlag: false,
      passDegree: 0,
      passLength: null,//密码值和长度的验证
      degreeColor: ''
    };
  },
  created() {
    this.getLoginUser();
  },
  methods: {
    getLoginUser() {
      this.http.global.getUserMsg().then((response) => {
        if (response.data.status === 200) {
          let userInfo = response.data.data;
          window.localStorage.setItem("user_msg", JSON.stringify(userInfo));
          this.$store.commit("setUserInfo", userInfo);
          this.user = userInfo;
          this.userMsgValue = {
            id: userInfo.id,
            username: userInfo.username,
            phone: userInfo.phone,
            sex: userInfo.sex,
            oldPassword: "",
            newPassword: "",
          };
        }
      });
    },
    updateUserMsg() {
      let that = this;
      that.$refs.roleRefForm.validate(valid => {
        if (valid) {
        that.http.user.updateOneselfUser(that.userMsgValue).then((res) => {
          if (res.data.status == 200) {
            that.$message.success(res.data.msg);
          } else {
            that.$message.warning(res.data.msg);
          }
          that.getLoginUser();
        });
        that.http.global.getUserMsg().then((response) => {
          if (response.data.status === 200) {
            let user = response.data.data;
            window.localStorage.setItem("user_msg", JSON.stringify(user));
            that.$store.commit("setUserInfo", user);
          }
        });
        } else {
          that.$message.warning('请填写正确信息')
          return false;
        }
      });
    },
    handlePassChange(){
      let password = this.userMsgValue.newPassword
      var passDegree = 0
      var passLength = null
      
       if (password == null || password.length < 6 || password.length > 24 || password.trim().length == "") {
          passLength = false;
        } else {
          passLength = true;
        }
        let arrVerify = [
          {regName: 'Number', regValue: /^.*[0-9]+.*/},
          {regName: 'LowerCase', regValue: /^.*[a-z]+.*/},
          {regName: 'UpperCase', regValue: /^.*[A-Z]+.*/},
          {regName: 'SpecialCharacters', regValue: /^.*[^a-zA-Z0-9]+.*/}
        ];
        for (let iReg = 0; iReg < arrVerify.length; iReg++) {
          if (arrVerify[iReg].regValue.test(password)) {
            passDegree = passDegree + 1;
          }
        }
        // if (passDegree <= 2) {
        //   msg = "密码必须包含大小写字母和数字";
        // }
        if(passDegree == 1){this.degreeColor = '#F56C6C'}
        if(passDegree == 2){this.degreeColor = '#E6A23C'}
        if(passDegree == 3){this.degreeColor = '#67C23A'}
        this.passLength = passLength
        this.passDegree = passDegree
        // console.log(passLength,passDegree)
    }
  },
};
</script>

<style scoped>
</style>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.main {
  padding: 14px;
  height: 100%;
  .card-sty {
    height: 100%;
    overflow-y: auto;
  }
}
.password-tips{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 8px 0 14px 0;
  // height: 24px;
  font-size: 12px;
  .degreeBox{
    display: flex;
    align-items: center;
    .d-item{
      display: flex;
      width: 40px;
      height: 6px;
      border-radius: 3px;
      background: #E4E7ED;
      margin-right: 14px;
    }
  }
}
</style>
